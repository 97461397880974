
import React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col, } from "react-bootstrap"
import { Link } from "gatsby"
import Header from "./header"
import "../components/layout.css"
import "../components/custom.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { FaLinkedin, FaEnvelope, FaPhone, FaMapMarkerAlt, FaTwitter, FaInstagram } from 'react-icons/fa';
import prolacsFooter from '../images/prolacs-logo-footer.png'
import konzekFooter from '../images/Konzek_Lite_white.svg'
import styled from 'styled-components';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      },
      logoWhite: file(relativePath: { eq: "prolacs-logo-white.png" }) {
        childImageSharp {
          fixed(width: 100) {
          ...GatsbyImageSharpFixed
          }
        }
      },
      logoKonzek: file(relativePath: { eq: "konzek-logo-white.png" }) {
        childImageSharp {
          fixed(width: 100) {
          ...GatsbyImageSharpFixed
          }
        }
      },
    },
  `)

  const FooterMenuRow = styled(Row)`
margin-top:1rem
`
  return (<>
    <Header siteTitle={data.site.siteMetadata.title} />
    {children}
    <div className='prolacs-footer'>
      <Container>
        <Row style={{ textAlign: 'center' }}>
          <Col>
            <Link to='/'> <img className='prolacs-footer-logo' src={prolacsFooter} alt="prolacs footer logo" /></Link>
          </Col>
        </Row>
        <FooterMenuRow>
          <Col sm className='footer-social-col '>
          <b><p>Solutions</p></b>
            <Link to="/solutions/smart-laundromat-control" style={{ fontSize: '15px !important' }}>
              Smart Laundromat Control
            </Link>
            <Link to="/solutions/laundry-management-system" style={{ fontSize: '15px !important' }}>
              Laundry Management System
            </Link>
          </Col>
          <Col sm className='footer-social-col'>
          <b><p>Products</p></b>
            <Link to="/products" style={{ fontSize: '15px !important ' }}>
              Tumble Washer Controllers
            </Link>
            <Link to="/products" style={{ fontSize: '15px !important' }}>
              Tumble Dryer Controllers
            </Link>
            <Link to="/products" style={{ fontSize: '15px !important' }}>
              Laundry 4.0 Smart Controllers
            </Link>
          </Col>
          <Col md className='footer-social-col '>
          <b><p>Get in touch</p></b>
            <a href="https://www.linkedin.com/company/prolacs"><FaLinkedin style={{ fontSize: '15px' }} /> </a>
            <a href="https://www.twitter.com/prolacs"><FaTwitter style={{ fontSize: '15px' }} /> </a>
            <a href="https://www.instagram.com/laundry4.0"><FaInstagram style={{ fontSize: '15px' }} /> </a>
            <a href="mailto:info@prolacs.com"><FaEnvelope style={{ fontSize: '15px' }} /></a> <br />
            <div className='footer-social-wrapper' >

              <a href="tel:+902163921642"><FaPhone style={{ fontSize: '15px' }} /> +90 (216) 392 16 42 </a>
              <a href="https://www.google.com/maps/place/Prolacs/@38.4901976,27.0319651,17z/data=!3m1!4b1!4m5!3m4!1s0x14bbdbcb4cf87fdf:0x620abbcacc1a496d!8m2!3d38.4901976!4d27.0341538" target="_blank" rel="noopener noreferrer" ><FaMapMarkerAlt style={{ fontSize: '15px' }} /> İzmir, Turkey</a>
            </div>
          </Col>
        </FooterMenuRow>
        <Row style={{ textAlign: 'center' }}>
          <Col sm className='footer-copy-title'>
            <a href="https://konzek.com/" title="Konzek Teknoloji A.Ş."> <img className='Konzek_Lite_white' style={{ width: '5rem', marginBottom: "0rem" }} src={konzekFooter} alt="Konzek Teknoloji A.Ş. Logo" /></a>
            <br></br>
            <p className='footer-copy'> All Rights Reserved, {(new Date().getFullYear())} </p>
          </Col>
        </Row>
        <br></br>
      </Container>
    </div>
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { Link } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import styled from 'styled-components';
import { Container } from "react-bootstrap"
import { FaLinkedin, FaEnvelope, FaTwitter, FaBars, FaInstagram } from 'react-icons/fa';
import headerLogo from '../images/prolacs-logo.png'
import headerReversedLogo from '../images/prolacs-logo-white.png'



class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: false,
      isTop: true
    };
    this.handleClick = this.handleClick.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }
  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 1000;
      if (isTop !== this.state.isTop) {
        this.onScroll(isTop);
      }
    });
  }
  onScroll(isTop) {
    this.setState({ isTop });
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {

    const Navbar = styled.div`
display: flex;
height: 2.5rem;
margin: 1rem 0;
width: -webkit-fill-available;
background: white;
transition: all .5s ease;
min-width:auto;
@media (max-width: 600px) {
  height: auto;
  transition: all .5s ease;
      display: block;
  }
`

    const NavbarImageWrapper = styled.div`
display: inline-block;
padding-top: .3125rem;
padding-bottom: .3125rem;
margin-right: 1rem;
font-size: 1.25rem;
line-height: inherit;
transition: all .5s ease;
white-space: nowrap;
`
    const NavbarLogo = styled.img`
width:8rem;
@media (max-width: 600px) {
  width:6rem;
  margin-bottom:0
  }

`
    const NavbarCollapseForItems = styled.div`
display:flex;
z-index:1200;
align-items:center;
width: 100%;
justify-content: flex-end;
@media (max-width: 600px) {
  display: ${this.state.isToggleOn === false ? 'none' : 'grid'};
  transition: all .5s ease;
  text-align: right;
  }
`

    const NavbarLink = styled(Link)`
padding:1rem;
color: ${this.state.isTop ? '#333333' : 'white!important'};
&:hover{
  transition: all .5 ease;
  color:#74FFC2
}
`;
    const NavbarContainer = styled(Container)`
position:sticky;
z-index:1900;
top:0
`;
    const NavbarContainerWrapper = styled.div`
background: ${this.state.isTop ? 'white' : '#333333'};
position:sticky;
top:0rem;
z-index:1900;
height:2.5rem;
@media (max-width: 600px) {
  height: 3.5rem;
  }
`;
    const NavbarTrigger = styled(FaBars)` 
cursor:pointer;
position:relative;
margin-left: auto;
align-self: center;
display: none;
color: ${this.state.isTop ? '#33333' : 'white'};
@media (max-width: 600px) {
  position: absolute;
  margin:1rem;
  margin-right:1.8rem;
  right: 0;
  display: unset;
  }
`;
    const NavbarSocialWrapper = styled.div`
display:none;
@media (max-width: 600px) {
display:unset;
  }
`;
    const NavbarSocialIcons = styled.div`

  svg{
    margin:1rem;
    color:${this.state.isTop ? '#333333' : 'white'};
  }
  svg:hover{
    color:#74FFC2
  }
`;

    return (<>
      <NavbarContainerWrapper>
        <NavbarContainer>
          <Navbar style={{ background: `${this.state.isTop ? 'white' : '#333333'}` }}>
            <NavbarImageWrapper>
              <Link to='/'><NavbarLogo src={this.state.isTop ? headerLogo : headerReversedLogo} alt="Prolacs Logo" /></Link>
            </NavbarImageWrapper>
            <NavbarTrigger onClick={this.handleClick} />
            <NavbarCollapseForItems>
              <NavbarLink to='/'>Home</NavbarLink>
              <NavbarLink to='/solutions'>Solutions</NavbarLink>
              <NavbarLink to='/products'>Products</NavbarLink>
              <NavbarLink to="/features">Features</NavbarLink>
              <NavbarLink to="/blog">Blog</NavbarLink>
              <NavbarLink to="/about" >About Prolacs</NavbarLink>
              <NavbarLink to="/contact">Contact</NavbarLink>
              <NavbarSocialWrapper>
                <NavbarSocialIcons>
                  <a href="https://www.linkedin.com/company/prolacs"><FaLinkedin style={{ fontSize: '20px' }} /> </a>
                  <a href="https://www.twitter.com/prolacs"><FaTwitter style={{ fontSize: '20px' }} /> </a>
                  <a href="https://www.instagram.com/laundry4.0"><FaInstagram style={{ fontSize: '20px' }} /> </a>
                  <a href="mailto:info@prolacs.com"><FaEnvelope style={{ fontSize: '20px' }} /></a> <br />
                </NavbarSocialIcons>
              </NavbarSocialWrapper>
            </NavbarCollapseForItems>
          </Navbar>
        </NavbarContainer>
      </NavbarContainerWrapper>
    </>
    )
  }
};
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}
export default Header

